<template>
  <div class="main" ref="element">
    <ValidationObserver ref="form">
      <b-loading
        :is-full-page="true"
        :active.sync="isUploadLoading"
        v-model="isUploadLoading"
      >
        <b-icon icon="spinner" custom-class="fa-spin"></b-icon>
        <span class="loading-percent">
          <h2 v-if="uploadPercentage < 100">
            <b>{{ uploadPercentage }}% uploaded</b>
          </h2>
          <h2 v-else><b>Saving to server</b></h2>
        </span>
      </b-loading>
      <form @submit.prevent="validateForm">
        <div>
          <div class="columns">
            <div class="column is-10">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <router-link :to="{ name: `interview` }">
                      <h3 class="is-size-3">INTERVIEW VIDEO</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3
                      class="is-size-3 breadcrumb-item"
                      v-if="$route.name == 'createUploadVideoInterview'"
                    >
                      UPLOAD
                    </h3>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="column is-2">
              <b-field grouped position="is-right">
                <b-button type="is-hcc" native-type="submit">Save</b-button>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <div>
                <div class="box">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <b-field grouped position="is-centered">
                        <div class="columns content">
                          <div class="column is-2">
                            <b>Case Study</b>
                            <b-tooltip
                              type="is-danger"
                              label="This field is required"
                            >
                              <span class="required">*</span>
                            </b-tooltip>
                          </div>
                          <div class="column is-10">
                            <ValidationProvider
                              :vid="`caseStudy`"
                              name="caseStudy"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-field
                                :type="errors.length > 0 ? 'is-danger' : null"
                              >
                                <b-taginput
                                  v-model="interview.caseStudy"
                                  :data="caseStudyTags"
                                  :open-on-focus="true"
                                  autocomplete
                                  placeholder="Type to search"
                                  @typing="getCaseStudy"
                                >
                                  <template slot="empty">
                                    There are no items
                                  </template>
                                </b-taginput>
                              </b-field>
                              <span class="required">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <b-field grouped position="is-centered">
                        <div class="columns content">
                          <div class="column is-2">
                            <b>Date</b>
                            <b-tooltip
                              type="is-danger"
                              label="This field is required"
                            >
                              <span class="required">*</span>
                            </b-tooltip>
                          </div>
                          <div class="column is-10">
                            <ValidationProvider
                              :vid="`date`"
                              name="date"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-datetimepicker
                                rounded
                                placeholder="Click to select..."
                                icon="calendar"
                                v-model="interview.interviewDate"
                              />
                              <span class="required">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <b-field grouped position="is-centered">
                        <div class="columns content">
                          <div class="column is-2">
                            <b>Note</b>
                            <b-tooltip
                              type="is-danger"
                              label="This field is required"
                            >
                              <span class="required">*</span>
                            </b-tooltip>
                          </div>
                          <div class="column is-10">
                            <ValidationProvider
                              :vid="`note`"
                              name="caseStudy"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <ckeditor
                                v-model="interview.note"
                                :config="editorConfig"
                              />

                              <span class="required">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <b-field grouped position="is-centered">
                        <div class="columns content">
                          <div class="column is-2">
                            <b>File</b>
                            <b-tooltip
                              type="is-danger"
                              label="This field is required"
                            >
                              <span class="required">*</span>
                            </b-tooltip>
                          </div>
                          <div class="column is-10">
                            <ValidationProvider
                              :vid="`video`"
                              name="video"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-field>
                                <span class="file-name">
                                  {{ interview.video.name }}
                                </span>
                                <b-upload
                                  class="file-label is-hcc"
                                  @input="uploadFileToBlob($event)"
                                  accept="video/*"
                                  v-model="interview.video"
                                >
                                  <a class="button is-hcc">
                                    <b-icon icon="upload"></b-icon>
                                    <span>Upload</span>
                                  </a>
                                </b-upload>
                                <span class="required">{{ errors[0] }}</span>
                              </b-field>
                            </ValidationProvider>
                            <div class="tags" v-if="interview.video.name">
                              <video
                                width="320"
                                height="240"
                                controls
                                v-if="interview.video"
                              >
                                <source v-lazy="blobUrl" />
                              </video>
                            </div>
                          </div>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
// import ApiService from "@/common/api.service";
import JwtService from '@/common/jwt.service'
import axios from 'axios'
export default {
  name: 'Register',
  data() {
    return {
      isLoading: true,
      isUploadLoading: false,
      isFullPage: true,
      interview: {
        video: {
          name: '',
        },
      },
      caseStudyTags: [],
      editorConfig: {
        extraAllowedContent: 'div[class]',
        allowedContent: true,
        extraPlugins: 'justify,font',
      },
      blobUrl: null,
      uploadPercentage: 0,
    }
  },
  created() {
    // if (this.$route.name == "createUploadVideoInterview") {
    //   this.fetchData();
    // }
  },
  watch: {},
  methods: {
    uploadFileToBlob(file) {
      let blobUrl = URL.createObjectURL(file)
      this.blobUrl = blobUrl
    },
    async fetchData() {
      this.isLoading = true
      const response = await this.$store.dispatch(
        'getInterviewSchedule',
        this.$route.params.interviewId
      )
      this.interview = response.data
      this.interview.zoomUrl = response.data.linkZoom
      this.interview.interviewDate = new Date(response.data.interviewDate)
      this.isLoading = false
    },
    getCaseStudy(search) {
      this.caseStudyTags = []
      var content = []
      content.search = search
      if (this.interview.caseStudy == undefined) {
        content.caseStudyList = []
      } else {
        content.caseStudyList = this.interview.caseStudy
      }
      this.$store.dispatch('searchCaseStudyLGD', content).then(data => {
        if (!data) {
          this.caseStudyTags = []
        } else {
          this.caseStudyTags = data.caseStudy
        }
      })
    },
    validateForm() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.danger('Silahkan mengisi semua form')
        } else {
          if (this.interview.video.name == '') {
            this.danger('Silahkan pilih video yang akan diupload')
          } else {
            this.processFormRegister()
          }
        }
      })
    },
    async processFormRegister() {
      this.isUploadLoading = true
      let formData = new FormData()
      formData.append('video', this.interview.video)
      formData.append('case_study', this.interview.caseStudy)
      formData.append('date', this.interview.interviewDate)
      formData.append('note', this.interview.note)

      const response = await axios({
        url:
          process.env.VUE_APP_BACKEND_URL +
          '/api/interview-schedule/upload-video',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`,
        },
        data: formData,
        onUploadProgress: function(progressEvent) {
          let data = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          )
          this.uploadPercentage = parseInt(data)
        }.bind(this),
        responseType: 'blob', // important
      }).catch(() => {
        this.danger('gagal mengupload video')
        this.isUploadLoading = false
      })
      if (response.status == 200) {
        this.success('berhasil mengupload video')
        this.$router.push({ name: 'interviewVideo' })
      } else {
        this.danger('gagal mengupload video')
      }
      this.isUploadLoading = false
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>
<style>
.delete-button {
  padding-left: 0 !important;
}
.role-play {
  width: 100% !important;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right: 1%;
}
.hero-body {
  padding-top: 1% !important;
  padding-bottom: 2% !important;
}
.content {
  width: 90%;
}
.loading-percent {
  padding-left: 1%;
}
</style>
